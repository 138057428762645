import {Chart} from "react-google-charts";
import {useEffect, useState} from "react";
import {ApiConsumer} from "../../api/ApiConsumer";


export function Doughnut({url = '', name = '', title = ''}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const colours = ['#5fa83f', '#f72585', '#118ab2', '#ff9770', '#d35400']

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(url)
            .then(res => {

                if (Array.isArray(res.data)) {
                    if (res.data.length > 0) {
                        if (typeof res.data[0] === 'object' && !Array.isArray(res.data[0])) {
                            let temp = [[name, 'Count']]
                            res.data.map(r => {
                                temp.push([r.name, r.count])
                            })
                            setData(temp)
                        } else if (Array.isArray(res.data[0])) {
                            setData(res.data)
                        }
                    }
                }


            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [url])

    const options = {
        title: "",
        pieHole: 0.5,
        is3D: false,
        legend: { position: 'bottom' },
        chartArea:{ width: '85%', height: '75%', top:'10' }
    };

    return <>
        <div className="min-w-0 flex-1">
            <div className="align-middle min-w-full overflow-x-auto shadow-sm overflow-hidden sm:rounded-lg">
                <h3 className='bg-white p-2 text-center'>{title}</h3>
                {
                    loading ? <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                        <svg
                            className="animate-spin -ml-1 mr-1 h-7 w-7 text-green-700 inline-block margin: auto"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    </div> : <Chart
                        chartType="PieChart"
                        width="100%"
                        height="250px"
                        data={data}
                        options={options}
                        className={'shadow-2xl rounded py-2 bg-white'}
                    />
                }
            </div>
        </div>
    </>
}
