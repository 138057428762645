import {useEffect, useState, useContext} from "react";

import {NavLink} from "react-router-dom";

import Table from "../../components/table";
import {PORTAL} from "../../routes/portal";
import {ApiConsumer} from "../../api/ApiConsumer";
import {API_ROUTES} from "../../routes/api";
import PageHeader from "../../components/page-header";
import {Doughnut} from "../../components/charts/doughnut";
import {StackedLine} from "../../components/charts/stacked";
import {HorizontalBar} from "../../components/charts/hbar";
import {useTextFormatHook} from "../../hooks/text-formatter";
import {ActiveKpaContext} from "../../contexts/active-kpa";

export default function AuditingDashboard() {
    let {normaliseTableData} = useTextFormatHook();
    const [kpas, setKpa] = useState([]);
    const [loaded, setLoaded] = useState(false);
    let {setActiveKpa} = useContext(ActiveKpaContext);

    const tableHeaders = [
        {
            key: "description",
            value: "Finding",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "severity",
            value: "Severity",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "risk_level",
            value: "Risk Level",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "status",
            value: "Status",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: true,
            showOnPrint: true,
        }
    ];

    console.log(API_ROUTES.AUDIT.FINDINGS)

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.AUDIT.FINDINGS)
            .then((res) => {
                res.data.sort((a, b) => a.created.localeCompare(b.created));
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setKpa(normalisedData.slice(0, 5));
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setKpa({});
            setLoaded(false);
        };
    }, []);

    return (
        <>
            <PageHeader pageHeaderName={`DASHBOARD - Audit Findings`}/>
            <>
                <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                    <h3 className="mb-2">Overview</h3>
                    <div className="flex space-x-3">
                        <Doughnut
                            url={API_ROUTES.AUDIT.GRAPH_BY_PRIORITY}
                            title={"Audits by Priority"}
                        />

                        <Doughnut
                            url={
                                API_ROUTES.AUDIT.GRAPH_BY_STATUS
                            }
                            title={"Audits by Status"}
                        />

                        <Doughnut
                            url={
                                API_ROUTES.AUDIT.GRAPH_BY_RISK
                            }
                            title={"Audits by Risk"}
                        />
                    </div>
                </div>



                <h2 className="flex-1 flex mx-full px-4 sm:px-6 lg:px-8 mt-8 text-lg leading-6 font-medium text-gray-900 text-left">
                    Latest Findings ({kpas?.length})
                </h2>
                <Table
                    tableTitle={`Audit Findings`}
                    tableHeaders={tableHeaders}
                    tableData={kpas}
                    editable={false}
                />
            </>
        </>
    );
}
