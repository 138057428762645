import {useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {ApiConsumer} from "../../api/ApiConsumer";

export function StackedLine({
                                url = "",
                                height = "250",
                                name = "",
                                title = "",
                            }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const options = {
        title: "",
        legend: {position: "bottom"},
        chartArea: {width: "75%"},
        isStacked: true,
        hAxis: {
            title: "",
            minValue: 0,
        },
        vAxis: {
            title: "",
        },
        series: {
            0: {color: "#0F9017"},
            1: {color: "#3366CC"},
        },
    };

    useEffect(() => {
        setLoading(true);
        ApiConsumer.get(url)
            .then((res) => {
                // let types = [];
                // res.data.types.map((x) => types.push(x.name));
                // let countries = [];
                // res.data.results.map((x) =>
                //     countries.indexOf(x.kpa) === -1
                //         ? countries.push(x.kpa)
                //         : null
                // );
                //
                // let final = [];
                // countries.slice(0, 5).map((c) => {
                //     let temp = [];
                //     temp.push(c);
                //     types.map((t) => {
                //         let x = res.data.results.filter(
                //             (x) => x.kpa === c && x.name === t
                //         );
                //         if (x.length === 0) {
                //             temp.push(0);
                //         } else {
                //             temp.push(x[0].count);
                //         }
                //     });
                //     final.push(temp);
                // });
                // const test = [["Kpa", ...types], ...final];
                setData(res.data);
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    }, [url]);

    return (
        <>
            <div className="min-w-0 flex-1">
                <div className="align-middle min-w-full overflow-x-auto shadow-sm overflow-hidden sm:rounded-lg">
                    <h3 className="bg-white p-2 text-center">{title}</h3>
                    {
                        loading ? <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                <svg
                                    className="animate-spin -ml-1 mr-1 h-7 w-7 text-green-700 inline-block margin: auto"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div> :
                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height={`${height}px`}
                                data={data}
                                options={options}
                                className={"shadow-2xl rounded"}
                            />
                    }
                </div>
            </div>
        </>
    );
}
